import React from 'react';
import FoldersContext from '../FoldersContext';
import ValidationError from '../ValidationError';
import config from '../config';

export default class AddFolder extends React.Component{
    static contextType = FoldersContext;
    constructor(props){
        super(props);
        this.folderNameInput = React.createRef();
    }

    validateName(){
        
        let folderName = this.folderNameInput.current.value;

        if(folderName.length === 0){
            this.context.checkForErrors(true);
            this.context.errorMessage('Folder name is required.');
        }else{
            this.context.checkForErrors(false);
            this.context.errorMessage('');
        }
    }

    handleSubmit(event){
        event.preventDefault();
        const folderName = this.folderNameInput.current.value;
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${config.API_KEY}` 
            }, 
            body: JSON.stringify({
                name: folderName
            })
        }
        console.log("folders >> >> ", options);
        fetch(`${config.API_ENDPOINT}/api/folders`, options)
        .then((response)=>{
            if(!response.ok){
                throw new Error('We were unable to add the folder at this time.');
            }
            return response;
        })
        .then((data)=>{
            this.folderNameInput.current.value = '';
            this.context.addFolder();
            this.context.checkForErrors(true);
            this.props.history.goBack();
        })
        .catch((err)=>{
            console.log(err)
        })
    }

    render(){
        return(
            <form className="addFolder-form">
                <h2>Add Folder</h2>
                <label htmlFor="addFolder">* Folder Name: </label>
                <input 
                    onChange={e => this.validateName(e)}
                    className=""
                    type="text" 
                    name="addFolder" 
                    id="addFolder"
                    ref={this.folderNameInput}
                    required
                />
                <button 
                    type="submit" 
                    className="addFolder-save"
                    onClick={e => this.handleSubmit(e)}
                    disabled={this.context.hasErrors}>
                    Save
                </button>
                <ValidationError message={this.context.error}/>
            </form>
        );
    }
}