import React from 'react';
import {withRouter} from 'react-router-dom';

class noteError extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          noteHasError: false
        };
      }
    
    static getDerivedStateFromError(error){
        return {noteHasError: true};
    }

    render(){
        const {history} = this.props;
        history.listen((location, action) => {
            if (this.state.noteHasError) {
                this.setState({
                  noteHasError: false,
                });
              }
          });
        if(this.state.noteHasError){
            return(
                <h2>Could not display Notes.</h2>
            );
        }
        return this.props.children;
    }
}

export default withRouter(noteError);