import React from 'react';
import {withRouter} from 'react-router-dom';

class FolderError extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          folderHasError: false
        };
      }
    
    static getDerivedStateFromError(error){
        return {folderHasError: true};
    }

    render(){
        const {history} = this.props;
        history.listen((location, action) => {
            if (this.state.folderHasError) {
                this.setState({
                  folderHasError: false,
                });
              }
          });
        if(this.state.folderHasError){
            return(
                <h2>Could not display folders.</h2>
            );
        }
        return this.props.children;
    }
}

export default withRouter(FolderError)