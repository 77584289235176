import React from 'react';
import FoldersContext from '../FoldersContext';
import ValidationError from '../ValidationError';
import config from '../config';

export default class AddNote extends React.Component{
    static contextType = FoldersContext;
    static hasErrors = true;
    constructor(props){
        super(props);
        this.noteNameInput = React.createRef();
        this.noteContentInput = React.createRef();
        this.noteFolderSelect = React.createRef();
    }

    validateInputs(){
        let nameInput = this.noteNameInput.current.value;
        let folderInput = this.noteFolderSelect.current.value;
        let contentInput = this.noteContentInput.current.value;

        if(nameInput.length !== 0 && folderInput !== 'NONE' && contentInput.length !== 0){
            this.context.checkForErrors(false);
            this.context.errorMessage('');
        }
    }

    handleSubmit(event){
        event.preventDefault();
        const noteName = this.noteNameInput.current.value;
        const content = this.noteContentInput.current.value;
        const folderId = this.noteFolderSelect.current.value;
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${config.API_KEY}` 
            }, 
            body: JSON.stringify({
                name: noteName,
                content: content,
                folder: parseInt(folderId)
            })
        }

        console.log('notes >> >> ', options);
        fetch(`${config.API_ENDPOINT}/api/notes`, options)
        .then((response)=>{
            if(!response.ok){
                throw new Error('We were unable to add the note at this time.');
            }
            return response;
        })
        .then((data)=>{
            this.noteNameInput.current.value = '';
            this.noteContentInput.current.value = '';
            this.noteFolderSelect.current.value = '';
            this.context.addNote();
            this.context.checkForErrors(true);
            this.props.history.goBack();
        })
        .catch((err)=>{
            console.log(err)
        })
    }

    render(){
        const folderOptions = this.context.folders.map(folder=>{
            return(
                <option key={folder.id} value={folder.id}>{folder.name}</option>
            );
        });
        
        const {history} = this.props;
        history.listen((location, action) => {
            this.context.checkForErrors(true);
        });

        return(
            <form className="addNote-form">
                <div className="form-header">
                    <h2>Add Note</h2>
                    <p>* Required field</p>
                    <p>All required fields must be filed in before you may add the note.</p>
                </div>
                <div className="form-nameInput">
                    <label htmlFor="addNote">*Name:</label>
                    <input 
                        className="note-name"
                        type="text" 
                        name="addNote" 
                        id="addNote"
                        ref={this.noteNameInput}
                        onChange={e => this.validateInputs(e)}
                        aria-required="true"
                        required
                    />
                </div>
                <div className="form-content">
                    <label htmlFor="addContent">*Content:</label>
                    <textarea
                        className="note-content"
                        type="text" 
                        name="addContent" 
                        id="addContent"
                        ref={this.noteContentInput}
                        onChange={e => this.validateInputs(e)}
                        aria-required="true"
                        required>
                    </textarea>
                </div>
                <div className="form-folderSelect">
                    <label htmlFor="newNoteFolder">* Folder:</label>
                    <select
                        className="note-folder"
                        type="text" 
                        name="newNoteFolder" 
                        id="newNoteFolder"
                        ref={this.noteFolderSelect}
                        onChange={e => this.validateInputs(e)}
                        aria-required="true">
                        <option value="NONE"></option>
                        {folderOptions}
                    </select>
                </div>
                <button 
                    type="submit" 
                    className="addNote-btn"
                    onClick={e => this.handleSubmit(e)}
                    disabled={this.context.hasErrors}>
                    Save
                </button>
                <ValidationError message={this.context.error}/>
            </form>
        );
    }
}