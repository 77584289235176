import React from 'react';
import { Route, Link, NavLink} from 'react-router-dom';
import MainPage from './MainPage/MainPage';
import FolderPage from './FolderPage/FolderPage';
import NotePage from './NotePage/NotePage';
import FoldersContext from './FoldersContext';
import AddFolder from './AddFolder/AddFolder';
import AddNote from './AddNote/AddNote';
import NoteError from './NoteError';
import FolderError from './FolderError';
import config from './config';
import './App.css';

class App extends React.Component{
  static contextType = FoldersContext;
    constructor(props){
    super(props);
    this.state = {
      folders: [],
      notes: [], 
      hasErrors: true,
      errMsg: ''
  }
} 

  setNotes = notes =>{
    this.setState({
      notes
    })
  }
  
  errorCheck = (value) =>{
    this.setState({
      hasErrors: value
    });
  }

  errorMessage = (message) =>{
    this.setState({
      errMsg: message
    });
  }


  renderNav = () => {
  const folders = this.state.folders.map(folder => {
      return(
        <li key={folder.id} className="folder">
            <NavLink 
              to={`/folder/${folder.id}`}
              activeClassName="selectedFolder"
              onClick={this.updateFolderList}>
                {folder.name}
            </NavLink>
        </li>
      );
    }); 
        return(
          <aside class="folder-list-aside">
            <ul className="folder-list">
              {folders}
              <li className="addFolder-btn">
                <Link to='/add-folder'>
                  Add folder
                </Link>
              </li>
            </ul>
          </aside>
        );
  }

  deleteNote = (noteId) => {
    const newNotes = this.state.notes.filter((note) => {
      return note.id !== noteId
    })

    this.setState({
      notes: newNotes
    })
  }

  deleteNoteRequest = (noteId, history) => {
    const options = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${config.API_KEY}`
      }
    }

    const url = `${config.API_ENDPOINT}/api/notes/${noteId}`
    fetch(url, options)
    .then((response) => {
      if(!response.ok){
        throw new Error('Sorry, but the note was not deleted.');
      }
      let path = '';
      this.deleteNote(noteId);
      this.getNotes();
      if(history.location.pathname.includes('/note/')){
        path = '/';
      }else{
        path = history.location.pathname;
      }
      history.push(path);
    })
    .catch((error) => {
      console.log(error);
    })
  }
  
  getFolders(){
    return(new Promise((resolve, reject) => {
      const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${config.API_KEY}` 
        }
      }
      fetch(`${config.API_ENDPOINT}/api/folders`, options)
      .then((response) => {
        if(!response.ok){
          throw new Error('Something went horribly wrong, jk.');
        }
        return response.json();
      })
      .then((data) => {
        if(data){
          resolve('finished running');
        }
        this.setState({
          folders: data
        })
      })
    .catch((error) => {
      reject(error);
    })
    }));
  }

  getNotes(){
    return(new Promise((resolve, reject) => {
      const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${config.API_KEY}` 
        }
      }
      fetch(`${config.API_ENDPOINT}/api/notes`, options)
      .then((response) => {
        if(!response.ok){
          throw new Error('Something went horribly wrong, jk.');
        }
        return response.json();
      })
      .then((data) => {
        if(data){
          resolve('finished running');
        }
        this.setState({
          notes: data
        })
      })
    .catch((error) => {
      reject(error);
    })
    }));
  }


  updateFolderList = () => {
    this.getFolders();
  }

  updateNoteList = () => {
    this.getNotes();
  }

  hasErrors = () =>{
    this.setState({
      hasError: true
    });
  }

  componentDidMount(){
    Promise.all([this.getFolders(),this.getNotes()])
    .then(([res1, res2])=>{
    })
    .catch((err)=>{
      console.log(err);
    })
  }

  render(){
    const contextValue = {
      folders: this.state.folders, 
      notes: this.state.notes,
      hasErrors: this.state.hasErrors,
      checkForErrors: this.errorCheck,
      errorMessage: this.errorMessage,
      error: this.state.errMsg,
      addFolder: this.updateFolderList,
      addNote: this.updateNoteList,
      deleteNote: this.deleteNoteRequest
    }
    return (
      <FoldersContext.Provider value={contextValue}>
      <div className="App">
        <header role="banner">
          <h1>
            <Link to='/'>
              Noteful
            </Link>
          </h1>
        </header>
        <main role="main">
       <FolderError>
        <Route
            exact path='/'
            render={()=>{
              return this.renderNav();
            }}
          />
          <Route
            exact path='/folder/:folderId'
            render={()=>{
              return this.renderNav();
            }}
          />
        <Route 
          exact path='/'
          component={MainPage}/>
        <Route 
          path='/folder/:folderId' 
          component={FolderPage}/>
       
        <Route
          exact path='/add-folder'
          component={AddFolder}/>
        
      </FolderError>
      <NoteError>
      <Route
          path='/note/:noteId'
          component={NotePage}/>
        <Route
          exact path='/add-note'
          component={AddNote}/>
      </NoteError>
      </main>
      <div>

      </div>
      </div>
      </FoldersContext.Provider>
    );
  }
}

export default App;
