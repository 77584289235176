import React from 'react';
import { Link } from 'react-router-dom';
import FoldersContext from '../FoldersContext';
import moment from 'moment';
import './NotePage.css';

function NotePage(props){
    return(
        <FoldersContext.Consumer>
            {(value) => {
                const {folders, notes} = value;
                function handleGoBack(){
                    props.history.push('/');
                }
                const note = notes.find((note) => {
                    return note.id == props.match.params.noteId;
                });
                const folder = folders.find((folder)=>{
                    return note.folder == folder.id
                });
                return(
                    <section className="notePage-section">
                        <aside>
                            <Link to='' onClick={handleGoBack}>
                                Go back
                            </Link>
                            <h2>{folder.name}</h2>
                        </aside>
                        <article className="note-display">
                            <section>
                                <h1>{note.name}</h1>
                                <p>Date modified on {moment(note.modified).format('Do MMM YYYY')}</p>
                                <Link to='' onClick={() => value.deleteNote(note.id, props.history)}>Delete Note</Link>
                            </section>
                            <p>{note.content}</p>
                        </article>
                    </section>
                );
            }}
        </FoldersContext.Consumer> 
    );
}

export default NotePage