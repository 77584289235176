import React from 'react';
import { Link } from 'react-router-dom';
import FoldersContext from '../FoldersContext';
import moment from 'moment';
import './FolderPage.css';

function FoldersPage(props){
    return(
        <FoldersContext.Consumer>
            {(value) => {
                const {folders, notes} = value;
                const folder = folders.find((folder) => {
                    return folder.id == props.match.params.folderId;
                });
                const notesList = notes.filter((note) => {
                    return note.folder == folder.id;
                }) 
                .map((note) => {
                    return(
                        <li key={note.id} className="note">
                            <h2><Link to={`/note/${note.id}`}>{note.name}</Link></h2>
                            <p>Date modified on {moment(note.modified).format('Do MMM YYYY')}</p>
                            <Link 
                                to={`${props.history.location.pathname}`} 
                                onClick={() => value.deleteNote(note.id, props.history)} 
                                className="delete-note">
                                Delete Note
                            </Link>
                        </li>
                    );
                });
                return(
                    <section className="notes-section">
                        <ul className="note-list">
                            {notesList}
                        </ul>
                        <Link to='/add-note' className="add-note">
                            Add note
                        </Link>
                    </section>
                );
            }}
    </FoldersContext.Consumer>
    );
        
}

export default FoldersPage