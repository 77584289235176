import React from 'react';
import { Link } from 'react-router-dom';
import FoldersContext from '../FoldersContext';
import moment from 'moment';
import './MainPage.css';

function MainPage(props){
    return(
        <FoldersContext.Consumer>
            {(value) => {
                const notes = value.notes.map(note => {
                    return(
                        <article key={note.id} className="note">
                            <section>
                                <h2><Link to={`/note/${note.id}`}>{note.name}</Link></h2>
                                <p>Date modified on {moment(note.modified).format('Do MMM YYYY')}</p>
                                <Link 
                                    to='' 
                                    onClick={() => value.deleteNote(note.id, props.history)}
                                    className="delete-note">
                                        Delete Note
                                </Link>
                            </section>
                        </article>
                    );
                });
                return(
                    <section className="note-list">
                        {notes}
                        <Link to='/add-note' className="add-note">
                            Add note
                        </Link>
                    </section>
                );
            }}
        </FoldersContext.Consumer>
    );
}

export default MainPage